// 公共类接口
import { Request } from '@/http/request'

// 永久文件上传
export function imgUpload (parameter: any) {
  return Request.axiosInstance.post('/system/upload/important-file', parameter ,{headers: {'Content-Type': 'multipart/form-data'}})
}

// 图片上传
export function imgWebUpload (parameter: any) {
  return Request.axiosInstance.post('/web/upload/important-file', parameter ,{headers: {'Content-Type': 'multipart/form-data'}})
}

// 临时文件上传
export function fileUpload (parameter: any) {
  return Request.axiosInstance.post('/system/upload/file', parameter, {headers: {'Content-Type': 'multipart/form-data'}})
}

export default { imgUpload, imgWebUpload, fileUpload }
