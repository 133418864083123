<template>
  <div class="SetLine">
    <div class="flex">
      <div class="df-item">材料配置选择</div>
    </div>
    <el-divider style="margin:12px 0 24px;"></el-divider>
    <el-button @click="goPreview" v-if="tablelist.length" class="backbtn r ">预览表单</el-button>
    <el-button @click="goChoose" v-if="tablelist.length" class="comfirebtn r">添加材料</el-button>
    <div class="flex center mt text-gray">线路名称: <span class="text-black" style="margin-left:10px;">{{lineName}}</span>
    </div>
    <div class="flex center mt text-gray">材料选择: <span style="margin-left:10px;">鼠标按住<icon-font type="icon-xuanzexiang">
        </icon-font>可以排序</span></div>
    <el-table :data="tablelist" v-if="tablelist.length" header-row-class-name="header-row"
      style="width: 100%;margin-top:10px;">
      <el-table-column width="50" label="#">
        <template #default>
          <icon-font type="icon-xuanzexiang"></icon-font>
        </template>
      </el-table-column>
      <el-table-column prop="formName" label="材料名称" />
      <el-table-column prop="required" label="是否必填" width="100">
        <template #default="scope">
          <el-switch v-model="scope.row.required" active-color="#637DFF" inactive-color="#BFBFBF" :active-value="0"
            :inactive-value="1"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="类型">
        <template #default="scope">
          {{itemTypeList[scope.row.formType]}}
        </template>
      </el-table-column>
      <el-table-column prop="description" label="材料说明">
        <template #default="scope">
          <div style="display:flex;align-items:center;">
            <span>{{scope.row.description}}</span>
            <icon-font type="icon-a-zu1750" @click="setEditIndex(scope.$index)"></icon-font>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="示例">
        <template #header>
          <div style="display:flex;align-items:center;">
            示例<icon-font type="icon-bangzhu2" style="font-size:20px;"></icon-font>
          </div>
        </template>
        <template #default="scope">
          <div style="display:flex;align-items:center;"
            v-if="scope.row.formType=='img'||scope.row.formType=='word'||scope.row.formType=='excel'">
            <div type="primary" :underline="false" @click="exampleUpload(scope.$index)">
              {{scope.row.exampleUrl?'重新上传':'上传示例'}}</div>
            <el-image style="width:50px;height:50px;margin-left:10px;" fix="cover" v-if="scope.row.exampleUrl"
              :preview-src-list="getSrcList(scope)" :src="scope.row.exampleUrl"></el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="模板">
        <template #header>
          <div style="display:flex;align-items:center;">
            模板<icon-font type="icon-bangzhu2" style="font-size:20px;"></icon-font>
          </div>
        </template>
        <template #default="scope">
          <div style="display:flex;align-items:center;"
            v-if="scope.row.formType=='img'||scope.row.formType=='word'||scope.row.formType=='excel'">
            <div type="primary"  style="margin-left:10px;color: #909399;" @click="modelUpload(scope.$index)">
              {{scope.row.modelUrl==''?'上传模板':'重新上传'}}</div>
            <el-link class="m-l-10" :underline="false" icon="el-icon-download" v-if="scope.row.modelUrl"
              :href="scope.row.modelUrl">下载</el-link>
            <el-popconfirm @confirm="scope.row.modelUrl = ''" v-if="scope.row.modelUrl" title="请确认删除"
              confirm-button-text="确定" cancel-button-text="取消">
              <template #reference>
                <div type="error" :underline="false" class="m-l-10">删除</div>
              </template>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="编辑" width="100">
        <template #default="scope">
          <el-switch v-model="scope.row.editPhoto" v-if="'img'==scope.row.formType" active-color="#637DFF"
            inactive-color="#BFBFBF" :active-value="0" :inactive-value="1"></el-switch>
        </template>
      </el-table-column>
    </el-table>
    <div v-else class="noconfig-context">
      <img class="noconfig-img" :src="require(`@/assets/images/configListEdit/noConfig.png`)">
      <span class="noconfig-text">暂无材料，请添加</span>
      <el-button class="noconfig-btn" @click="goChoose">添加材料</el-button>
    </div>
    <el-button @click="back" v-if="tablelist.length" class="backbtn m-t-10">取消</el-button>
    <el-button @click="saveLine" v-if="tablelist.length" class="comfirebtn m-t-10">保存</el-button>
    <el-dialog v-model="editDialog" width="30%" title="材料说明">
      <el-input type="textarea" v-model="editDescription" placeholder="请输入材料说明" rows="10"></el-input>
      <template #footer>
        <div class="dialogfooter">
          <el-button class="cancel" @click="handleDialogCancel">取消</el-button>
          <el-button class="comfirm" @click="handleDialogComfirm">确定</el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import img from '@/api/common/img'
import { IconFont } from '@/utils/iconfont'
import lineCard from './lineComponent/lineCard.vue' //分页item
import linePopup from './lineComponent/linePopup.vue' //新增弹框
import {
  queryLineFormByLineId,
  updateLineFormServiceMsg,
} from '@/api/config/config'
export default {
  name: 'SetLine',
  components: {
    IconFont,
    lineCard,
    linePopup,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        pageSize: 52,
        mode: '0',
        sortBy: ['createTime'],
        sortDesc: ['false'],
      },
      tablelist: [],
      itemTypeList: {
        img: '图片',
        video: '视频',
        input: '输入框',
        radio: '单选',
        word: '文档',
        excel: '表格',
        checkbox: '多选',
        select: '下拉选择',
        upload: '上传',
        date: '日期',
        cascader: '级联',
        mobileTextarea: '电话文本域',
      },
      lineId: -1,
      lineName: '',
      dragIndex: 0,
      enterIndex: 0,
      editIndex: 0,
      editDialog: false,
      editDescription: '',
    }
  },
  mounted() {
    // 监听滚动事件
    // this.divScrollChange()
    // let onselectList = JSON.parse(localStorage['onselectForm'])
    // this.tablelist = onselectList.map((e) => {
    //   return JSON.parse(localStorage[e])
    // })
    this.lineId = this.$route.query.id || -1
    this.lineName = this.$route.query.name
      ? unescape(this.$route.query.name)
      : '未定义'
    this.getDataList()
  },
  methods: {
    handleDialogComfirm() {
      this.tablelist[this.editIndex].description = this.editDescription
      this.editDescription = ''
      this.editDialog = false
    },
    handleDialogCancel() {
      this.editDescription = ''
      this.editDialog = false
    },
    setEditIndex(index) {
      this.editIndex = index
      this.editDescription = this.tablelist[index].description
      this.editDialog = true
    },
    saveLine() {
      // let rows = document.getElementsByClassName('el-table__row')
      let parm = this.tablelist.map((e, i) => {
        console.log(e)
        let { id, description, exampleUrl, modelUrl, required, editPhoto,ranking } = e
        return {
          id,
          description,
          exampleUrl,
          required,
          modelUrl,
          editPhoto,
          ranking:i,
          // ranking: parseInt(rows[i].getAttribute('ranking')),
        }
      })
      const loading = this.$loading()
      updateLineFormServiceMsg(parm)
        .then((res) => {
          this.$notify.success({
            message: '保存成功',
          })
          loading.close()
        })
        .catch((err) => {
          loading.close()
        })
    },
    dragenter(e) {
      //when element hover item ,reset current enter index
      if (
        ~e.target.parentElement.getAttribute('class').indexOf('el-table__row')
      ) {
        this.enterIndex = parseInt(
          e.target.parentElement.getAttribute('ranking') - 1
        )
      }
      // return false
    },
    dragend(e) {
      // if (this.dragEnter != null) {
      //   this.dragitem.parentElement.removeChild(this.dragitem)
      //   this.dragEnter.appendChild(this.dragitem)
      // }
      //if enterindex is not equals to dragIndex
      //if enterindex is bigger than dragIndex :1,0
      // console.log(this.enterIndex, this.dragIndex)
      if (this.enterIndex > this.dragIndex) {
        //set temp save dragIndex item
        const temp = this.tablelist[this.dragIndex]
        for (let i = this.dragIndex; i < this.enterIndex; i++) {
          //set current item as next item
          this.tablelist[i] = this.tablelist[i + 1]
        }
        //the end , set enter as temp
        this.tablelist[this.enterIndex] = temp
      } else if (this.enterIndex < this.dragIndex) {
        //set temp save enterIndex item
        const temp = this.tablelist[this.dragIndex]
        for (let i = this.dragIndex; i > this.enterIndex; i--) {
          //set current item as before item
          this.tablelist[i] = this.tablelist[i - 1]
        }
        //the end , set enter as temp
        this.tablelist[this.enterIndex] = temp
      }
      //resetrankingIndex
      this.$forceUpdate()
      this.$nextTick(() => {
        let rows = document.getElementsByClassName('el-table__row')
        rows.forEach((e, i) => {
          e.setAttribute('ranking', i + 1)
        })
      })
      // console.log({ dragend: e })
    },
    dragstart(e) {
      //get current selected index
      // console.log(e.target.getAttribute('ranking'));
      this.dragIndex = parseInt(e.target.getAttribute('ranking')) - 1
    },
    //上传函数
    upload(type, formData, cb) {
      if (type == 'img') {
        img.imgWebUpload(formData).then((res) => {
          if (res) {
            cb(res.data.url)
          }
        })
      } else {
        img.imgUpload(formData).then((res) => {
          if (res) {
            cb(res.data.url)
          }
        })
      }
    },
    getSrcList(scope) {
      // console.log({scope});
      // return [scope.row.example];
      // console.log(this.tablelist.map(e=>{if(e.example) {return e.example}}));
      let list = []
      this.tablelist.forEach((e) => {
        if (e.exampleUrl) {
          list.push(e.exampleUrl)
        }
      })
      return list
    },
    goPreview() {
      this.$router.push({ path: '/line/preview', query: { id: this.lineId } })
    },
    goChoose() {
      this.$router.push({
        path: '/line/configChoose',
        query: { id: this.lineId },
      })
    },
    getDataList() {
      const loading = this.$loading({
        title: 'LOADING',
      })
      queryLineFormByLineId(this.lineId)
        .then((res) => {
          loading.close()
          this.tablelist = res.data
          this.tablelist.sort(function (first, second) {
            return first.ranking - second.ranking
          })
          this.tablelist.forEach((e) => {
            if (e.editPhoto === undefined || e.editPhoto === null) {
              e.editPhoto = 1
            }
          })
          // debugger
          // this.$forceUpdate()
          const interid = setInterval(() => {
            let rows = document.getElementsByClassName('el-table__row')
            if (rows.length == res.data.length) {
              clearInterval(interid)
              if (rows.length == 0) {
                return
              }
              const rowArray = Array.from(rows);
              rowArray.forEach((e, i) => {
                e.setAttribute('draggable', 'true')
                e.setAttribute('ranking', i + 1)
                e.addEventListener('dragstart', this.dragstart)
                e.addEventListener('dragenter', this.dragenter)
                e.addEventListener('dragend', this.dragend)
              })
            }
          }, 150)
        })
        .catch((err) => {
          console.log(err)
          loading.close()
        })
    },
    back() {
      this.$router.push({ path: '/line/SetLine' })
    },
    exampleUpload(index) {
      let input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.addEventListener('change', (e) => {
        let file = input.files[0]
        document.body.removeChild(input)
        // console.log(file);
        let imgs = ['jpg', 'jpeg', 'png', 'bmp']
        if (!~imgs.indexOf(file.name.split('.')[1])) {
          return this.$message.error('上传示例只接受图片：jpg,jpeg,png,bmp格式')
        }
        let form = new FormData()
        form.append('file', file)
        this.upload('img', form, (url) => {
          this.tablelist[index].exampleUrl = url
        })
      })
      document.body.appendChild(input)
      input.click()
      input.style.opacity = 0
      input.style.display = 'none'
    },
    modelUpload(index) {
      let input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.addEventListener('change', (e) => {
        let file = input.files[0]
        document.body.removeChild(input)
        // console.log(file);
        let form = new FormData()
        form.append('file', file)
        this.upload('doc', form, (url) => {
          this.tablelist[index].modelUrl = url
          // console.log({ url })
        })
      })
      document.body.appendChild(input)
      input.click()
      input.style.opacity = 0
      input.style.display = 'none'
    },
  },
}
</script>
<style lang="scss" scoped>
.m-l-10 {
  margin-left: 10px !important;
  color: #909399;
}
.m-t-10 {
  margin-top: 10px !important;
}
::v-deep(.div [class*='el-icon-'] + span) {
  margin: 0;
}
.comfirebtn {
  float: left;
  margin: 0 0 0 10px;
  width: 80px;
  height: 32px;
  background: #637dff;
  border-radius: 4px;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  &.r {
    float: right;
    margin: 0 10px 0 0;
  }
}
.backbtn {
  float: left;
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #999999;
  display: flex;
  justify-content: center;
  align-items: center;
  &.r {
    float: right;
    margin: 0 10px 0 0;
  }
}
.SetLine {
  padding-right: 1.125rem;
}
.lineCard-show-box {
  padding-top: 20px;
  height: calc(100vh - 250px);
  overflow-y: overlay;
}
.type-name {
  width: 80px;
}
.line-in-button {
  width: 100%;
}
.button-left {
  width: 100%;
  color: #333333;
  background: #f2f3f8;
}
.button-right {
  width: 100%;
  color: #ffffff;
  background: #637dff;
}
.font-refresh-16 {
  font-size: 14px;
  color: #637dff;
}
.df-item {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #09132d;
  letter-spacing: 0px;
  margin: 0 20px;
  cursor: pointer;
  &.active {
    color: #637dff;
  }
}
.flex {
  display: flex;
  &.center {
    align-items: center;
  }
  &.mt {
    margin-top: 10px;
  }
}
.text-gray {
  color: #86909c;
}
.text-black {
  color: #000;
}
.configtext {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #09132d;
}
.append-margin {
  margin-top: 15px;
}
::v-deep(.header-row th) {
  background: #eeeef3;
}
.button-text-edit {
  color: #637dff;
}
.button-text-del {
  color: #ff5757;
}
.noconfig-context {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .noconfig-img {
    width: 113px;
    margin-top: 180px;
  }
  .noconfig-text {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #86909c;
    margin-top: 28px;
  }
  .noconfig-btn {
    width: 108px;
    height: 32px;
    background: #637dff;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 34px;
  }
}
.dialogfooter {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .cancel {
    width: 94px;
    height: 36px;
    background: #eeeeee;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #131523;
    letter-spacing: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .comfirm {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 94px;
    height: 36px;
    background: #637dff;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    letter-spacing: 0px;
  }
}
.model_url {
  margin-left: 10px;
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>